<!--
 * @Description: 复制弹出框
 * @version: 
 * @Author: PSG
 * @Date: 2021-03-23 11:45:35
 * @LastEditors: HWL
 * @LastEditTime: 2021-07-06 14:46:45
-->
<template>
  <div>
    <a-modal 
      v-model:visible="modalVisible" 
      title="复制问卷" 
      @ok="handleSubmit"
      class="titleDescModals"
      :closable="false"
      width="1046px"
      @cancel="handleCloseModal"
    >
      <span class="iconfont close" @click="handleCloseModal">&#xe61c;</span>
      <div class="main">
        <div>问卷标题：</div>
        <a-textarea v-model:value="title" allow-clear auto-size/>
      </div>
    </a-modal>
  </div>
</template>
<script>
import { reactive, ref, watchEffect, toRefs } from 'vue'
import { copyQuestionnaire } from '@/api/questionnaire'
import { message } from 'ant-design-vue'

export default({
  name: 'CopyModal',
  props: {
    modalVisible: Boolean,
    editRow: Object,
  },
  setup(props, { emit }) {
    const title = ref(props.editRow.title)
    const handleCloseModal = () => {
      emit('handleModal')
    }
    // 复制操作
    const handleSubmit = async () => {
      if (!title.value) {
        message.error('问卷名不能为空')
        return
      }
      const res = await copyQuestionnaire({
        questionnaireProtoId: props.editRow.id,
        title: title.value,
      })
      if (res.status === 200 && res.data?.code === '00000') {
        message.success('复制成功')
        emit('getTableList')
        handleCloseModal()
      } else {
        message.error('复制失败，失败原因：' + res.msg)
      }
    }
    return {
      handleCloseModal,
      handleSubmit,
      title
    }
  },
})
</script>

<style lang='less'>
.titleDescModals {
  .main {
    width: 100%;
    display: flex;
    justify-content: center;
    font-size: 16px;
    line-height: 41px;
    font-weight: 100;
    > div:nth-child(1) {
      width: 130px;
      text-align: right;
      white-space: nowrap;
    }
  }
  .ant-modal-content {
    position: relative;
    border: 16px solid #fff;
    border-radius: 16px;
    .ant-modal-header {
      border: none;
      .ant-modal-title {
        font-size: 20px;
        font-weight: bold;
      }
    }
    .ant-modal-body {
      .close {
        position: absolute;
        top: 8px;
        right: 24px;
        font-size: 25px;
        color: #00A0E9;
        .pointer()
      }
      .ant-input-affix-wrapper {
        min-height: 41px;
        border: 1px solid #d7d7d7;
        .ant-input {
          resize:none;
          line-height: 31px;
        }
        span {
          line-height: 31px;
        }
        svg {
          font-size: 18px;
        }
      }
    }
    .ant-modal-footer {
      border: none;
      div {
        text-align: center;
        button {
          height: 36px;
          width: 108px;
          font-weight: 100;
          color: #D3D3DD;
        }
        .ant-btn-primary {
          margin-left: 32px;
          color: #fff;
        }
        // button {
        //   background-color: #f59a23;
        //   border: none;
        //   color: #fff;
        //   &:nth-child(1) {
        //     // display: none;
        //   }
        // }
      }
    }
  }
  .ant-modal-footer {
    border: none;
    div {
      text-align: center;
      // button {
      //   background-color: #f59a23;
      //   border: none;
      //   color: #fff;
      //   &:nth-child(1) {
      //     // display: none;
      //   }
      // }
    }
  }
}
</style>