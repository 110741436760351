<!--
 * @Description: 删除弹窗框
 * @version: 
 * @Author: HWL
 * @Date: 2021-07-06 17:42:16
 * @LastEditors: HWL
 * @LastEditTime: 2021-07-07 14:56:57
-->
<template>
  <div>
    <a-modal 
      v-model:visible="visible" 
      :title="title" 
      class="tipsModal"
      :closable="false"
      width="1046px"
      @ok="emit('ok')"
      @cancel="emit('close')"
    >
      <span class="iconfont close" @click="emit('close')">&#xe61c;</span>
      <div class="main">
        <p>{{ context }}</p>
      </div>
    </a-modal>
  </div>
</template>
<script>

export default {
  name: 'TipsModal',
  props: [
    'visible',
    'title',
    'context',
  ],
  setup(props, { emit }) {
    return {
      emit
    }
  }
}
</script>
<style lang="less">
.tipsModal {
  .main {
    p {
      text-align: center;
      color: #7F7F88;
      font-weight: 100;
      font-size: 16px;
    }
  }
  .ant-modal-content {
    position: relative;
    border: 16px solid #fff;
    border-radius: 16px;
    .ant-modal-header {
      padding-top: 50px;
      padding-bottom: 0px;
      border: none;
      .ant-modal-title {
        font-size: 20px;
        font-weight: bold;
        text-align: center;
      }
    }
    .ant-modal-body {
      .close {
        position: absolute;
        top: 8px;
        right: 24px;
        font-size: 25px;
        color: #00A0E9;
        .pointer()
      }
      .ant-input-affix-wrapper {
        min-height: 41px;
        border: 1px solid #d7d7d7;
        .ant-input {
          resize:none;
          line-height: 31px;
        }
        span {
          line-height: 31px;
        }
        svg {
          font-size: 18px;
        }
      }
    }
    .ant-modal-footer {
      border: none;
      div {
        text-align: center;
        button {
          height: 36px;
          width: 108px;
          font-weight: 100;
          color: #D3D3DD;
        }
        .ant-btn-primary {
          margin-left: 32px;
          color: #fff;
        }
      }
    }
  }
  .ant-modal-footer {
    border: none;
    div {
      text-align: center;
    }
  }
}
</style>