/*
 * @Description: 提示弹窗操作
 * @version: 
 * @Author: HWL
 * @Date: 2021-07-06 17:57:16
 * @LastEditors: HWL
 * @LastEditTime: 2021-07-12 17:54:38
 */
import { ref } from 'vue'

function tipsEffect(refresh) {
  const isShowModal = ref(false)
  const title = ref('')
  const context = ref('')
  const execFuncList = {}
  let key
  let params

  const toggleShowModel = () => {
    isShowModal.value = !isShowModal.value
  }

  const registerFunc = (key, func) => {
    execFuncList[key] = func
  } 
  
  const execFunc = async () => {
    if (execFuncList[key]) {
      await execFuncList[key](params)
      toggleShowModel()
    }
  }

  const handleTips = (newKey, newParams, newTitle, newContext) => {
    key = newKey
    params = newParams
    title.value = newTitle
    context.value = newContext
    toggleShowModel()
    refresh()
  }

  return {
    isShowModal, title, context,
    handleTips,
    registerFunc,
    toggleShowModel,
    execFunc
  }
}

export default tipsEffect
