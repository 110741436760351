<!--
 * @Description: 
 * @version: 
 * @Author: PSG
 * @Date: 2021-03-09 11:27:29
 * @LastEditors: PSG
 * @LastEditTime: 2021-07-28 11:07:06
-->
<template>
  <Header
    :isShowLogo="true"
    :isShowHelp="true"
  />
  <div class="personal">
    <div class="content">
      <div class="top">
        <div class="top__left">
          <div class="top__left__text">问卷列表</div>
          <div class="top__left__tab">
            <div class="top__left__tab__all">全部问卷</div>
            <!-- <div class="top__left__tab__file">文件夹</div> -->
          </div>
        </div>
        <div class="top__right" >
          <div class="top__right__btn">
            <a-button class="top__right__btn__trash" @click="handleRecycleBinClick">
              <template #icon>
                <span class="iconfont">&#xe62c;</span>
              </template>
              回收站
            </a-button>
            <a-button class="top__right__btn__new" @click="handleNewClick">
              <template #icon><PlusOutlined/></template>新建问卷
            </a-button>
            <div class="wrap">
              <a-input-search
                class="search"
                v-model:value="search"
                placeholder="请输入关键字"
                @search="onSearch"
              />
            </div>
            
            <!-- <a-button class="top__right__btn__new" @click="handleLogout">
              <template #icon></template>退出登录
            </a-button> -->

          </div>
          <!-- <div class="top__right__info">
          <div class="top__right__info__file">文件夹</div>
            <div class="top__right__info__status">状态</div>
            <div class="top__right__info__searh">搜索</div>
          </div> -->
        </div>
      </div>
      <div class="list">
        <QuestionnaireItem 
          v-for="item in list"
          :key="item.id"
          :item="item"
          @delete="handleTips('del', {id: item.id}, `确定要删除(${item.title})吗？`, '(删除后该问卷将移入回收站，可到回收站恢复或者彻底删除)')"
          @preview="handlePreviewClick"
          @eidt="handleEditClick"
          @copy="handleCopyModal"
          @release="handleTips('release', {id: item.id, ifRelease: item.ifRelease, refresh}, buildTitle(item.ifRelease), buildContext(item.ifRelease))"
          @recycle="handleRecycleClick"
          @send="handleSendClick"
          @analysis="handleAnalysisClick"
        />
      </div>
      <Pager 
        class="pager" 
        :current="current"
        :total="total"
        :size="size"
        @onPageChange="onPageChangeEvent"
      />
    </div>
    <CopyModal
      v-if="copyModalVisible" 
      :modalVisible="copyModalVisible"
      :editRow="copyRow.data"
      @handleModal="handleCopyModal"
      @getTableList="getTableList"
    ></CopyModal>
    <TipsModal
      v-if="isShowModal" 
      :visible="isShowModal"
      :title="title"
      :context="context"
      @ok="execFunc"
      @close="toggleShowModel"
    ></TipsModal>
  </div>
  
</template>

<script>
import Header from '@/components/common/QuestionHeader'
import { reactive, toRefs, ref, watchEffect, onUnmounted } from 'vue'
import { PlusOutlined } from '@ant-design/icons-vue';
import QuestionnaireItem from './components/QuestionnaireItem'
import Pager from '@/components/common/Pager'
import { getQuestionnaireList, deleteQuestionnaire, releaseQuestionnaire, cancelReleaseQuestionnaire } from '@/api/questionnaire'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { message } from 'ant-design-vue'
import { Modal } from 'ant-design-vue'
import CopyModal from './components/CopyModal.vue'
import TipsModal from './components/TipsModal'
import delEffect from './effects/delEffect'
import tipsEffect from './effects/tipsEffect'
import releaseEffect from './effects/releaseEffect'

/**
 * 获取问卷信息
 */
const getQuestionnaireListEffect = (pageNumber, pageSize, titleOrDateNumId) => {
  const questionnaireList = reactive({
    list: [],
    current: 1,
    size: 5,
    total: 999
  })
  const getList = async (page, size, titleOrDateNumId) => {
    const params = {
      page,
      size,
      titleOrDateNumId,
      sort: 'modifyTime,desc' // 日期降序排序
    }
    const result = await getQuestionnaireList(params)
    if (result.status === 200 && result.data?.code === '00000') {
      const resData = result.data.data
      questionnaireList.list = resData.content
      questionnaireList.current = resData.number
      questionnaireList.total = resData.totalElements
      questionnaireList.size = resData.size
    }
  }
  getList(pageNumber, pageSize, titleOrDateNumId)
  // const getList = async () => {
  //   const result = await axios.get('/personal/list', data, {})
  //   console.log('请求回来的列表数据：', result)
  //   if (result.status === 200 && result.data?.code === '00000' && result?.data?.data) {
  //     const resData = result.data
  //     questionnaireList.list = resData.data.content
  //     questionnaireList.current = resData.data.number
  //     questionnaireList.total = resData.data.totalPages
  //     questionnaireList.size = resData.data.size
  //   }
  // }
  // getList()

  // 刷新数据
  const refresh = () => {
    getList(0, 5)
  }
  const { list, current, total, size } = toRefs(questionnaireList)
  return { list, current, total, size, refresh }
}

/**
 * 问卷操作相关方法
 */
const questioinnaireSettingsEffect = (refresh) => {
  const router = useRouter()
  // 新增操作
  const handleNewClick = () => {
    router.push('/template')
  }
  // 删除操作
  const handelDeleteClick = (id) => {
    Modal.confirm({
      content: '是否删除问卷？（删除后该问卷将移入回收站，可到回收站恢复或彻底删除）',
      cancelText: '取消',
      onOk() {
        return new Promise(async (resolve, reject) => {
          const res = await deleteQuestionnaire({
            questionnaireProtoId: id,
            option: "SOFT_DELETE"
          })
          if (res.status === 200 && res.data?.code === '00000') {
            resolve()
            message.success('删除成功')
            refresh()
          } else {
            reject()
            message.error('删除失败，失败原因：' + res.msg)
          }
        }).catch(() => console.log('errors!'));
      },
      onCancel() {
        Modal.destroyAll();
      },
    })
  }
  // 编辑操作
  const handleEditClick = (item) => {
    if (item.ifRelease) {
      router.push(`/detail/${item.id}/editQuestion`)
    } else if (!item.ifRelease &&
      (item.inValidQuestionnaireNum > 0 || item.validQuestionnaireNum > 0)
    ) { // 未发布且有问卷
      router.push(`/detail/${item.id}/editQuestion`)
    } else {
      router.push(`/edit/questionEdit/${item.id}`)
    }
  }
  // 预览操作
  const handlePreviewClick = (id) => {
    const obj = router.resolve({
      path: `/preview/${id}`,
    });
    window.open(obj.href, "_blank");
    // router.push(`/preview/${id}`)
  }
  
  // 发布操作
  const handleReleaseClick = (id, ifRelease) => {
    const tips = ifRelease ? '暂停' : ''
    Modal.confirm({
      content: `是否${tips}发布问卷？`,
      cancelText: '取消',
      onOk() {
        return new Promise(async (resolve, reject) => {
          let res = null
          if (ifRelease) {
            res = await cancelReleaseQuestionnaire(id)
          } else {
            res = await releaseQuestionnaire(id)
          }
          if (res.status === 200 && res.data?.code === '00000') {
            message.success(`${tips}发布成功`)
            !tips && router.push(`/detail/${id}/dispatchQuestion`)
            resolve()
            tips && refresh()
          } else {
            message.error(`${tips}发布失败，失败原因：${res.msg}`)
            reject()
          }
        }).catch(() => console.log('errors!'));
      },
      onCancel() {
        Modal.destroyAll();
      },
    })
  }

  //问卷派发
  const handleSendClick = (id) => {
    router.push(`/detail/${id}/dispatchQuestion`)
  }

  // 查看问卷回收情况
  const handleRecycleClick = (id) => {
    router.push(`/detail/${id}/recycleBin`)
  }

  // 查看问卷结果分析
  const handleAnalysisClick = (id) => {
    router.push(`/detail/${id}/analysis`)
  }

  const handleRecycleBinClick = () => {
    router.push('/recycleBin')
  }

  return {
    handleNewClick,
    handelDeleteClick,
    handleEditClick,
    handlePreviewClick,
    handleReleaseClick,
    handleRecycleClick,
    handleSendClick,
    handleAnalysisClick,
    handleRecycleBinClick,
  }
}

/**
 * 翻页操作
 */
const pageChangeEffect = (list, current, size, total, search) => {
  const onPageChangeEvent = (pageNumber, pageSize) => {
    if (!search.value) search.value = undefined
    const data = getQuestionnaireListEffect(pageNumber, pageSize, search.value)
    list.value = data.list
    current.value = pageNumber
    size.value = pageSize
    total.value = data.total
  }
  return {
    onPageChangeEvent
  }
}

// 使用复制弹窗
function useCopyModal(refresh) {
  const copyModalVisible = ref(false)
  let copyRow = reactive({
    data: null
  })
  const handleCopyModal = (obj) => {
    if (obj) {
      copyRow.data = obj
    } else {
      copyRow.data = null
    }
    copyModalVisible.value = !copyModalVisible.value
  }
  const getTableList = () => {
    refresh()
  }
  return {
    copyModalVisible, copyRow, handleCopyModal, getTableList,
  }
}

export default {
  components: {
    Header,
    PlusOutlined,
    QuestionnaireItem,
    Pager,
    CopyModal,
    TipsModal
  },
  /**
   * 程序入口
   */
  setup () {
    const search = ref('')
    let { list, current, total, size, refresh} = getQuestionnaireListEffect(0, 5)
    const { handleNewClick, handelDeleteClick, handleEditClick, handlePreviewClick,
      handleReleaseClick, handleRecycleBinClick, handleRecycleClick, handleSendClick, handleAnalysisClick } = questioinnaireSettingsEffect(refresh)
    const { onPageChangeEvent } = pageChangeEffect(list, current, size, total, search)
    const { copyModalVisible, copyRow, handleCopyModal, getTableList} = useCopyModal(refresh)

    const { isShowModal, title, context, handleTips, registerFunc, execFunc, toggleShowModel } = tipsEffect(refresh)
    const { buildTitle, buildContext, handleOk } = releaseEffect()
    registerFunc('del', delEffect().handleOk)
    registerFunc('release', handleOk)

    const onSearch = () => {
      if (!search.value) search.value = undefined
      const data = getQuestionnaireListEffect(0, size.value, search.value)
      list.value = data.list
      current.value = 0
      total.value = data.total
    }
    
    return {
      list, 
      current, 
      total, 
      size,
      onPageChangeEvent,
      handleNewClick,
      handelDeleteClick,
      handleEditClick,
      handlePreviewClick,
      handleReleaseClick,
      handleRecycleClick,
      handleRecycleBinClick,
      handleSendClick,
      handleAnalysisClick,
      copyModalVisible, copyRow, handleCopyModal, getTableList,
      search,
      onSearch,
      isShowModal, title, context, execFunc, handleTips, toggleShowModel,
      buildTitle, buildContext, refresh
    }
  }
}
</script>

<style lang="less" scoped>
.personal {
  position: absolute;
  top: 72px;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
  background-color: @backgroundColor;
}
.content {
  width: 1300px;
  margin: 20px auto;
  background: #fff;
  border-radius: 10px;
}
.top {
  padding-top: 60px;
  margin: 0 50px 16px 50px;
  height: 181px;
  display: flex;
  justify-content: space-between;
  &__left {
    width: 320px;
    position: relative;
    &__text {
      font-size: 26px;
      font-weight: bold;
    }
    &__tab {
      display: flex;
      justify-content: space-between;
      width: 160px;
      position: absolute;
      bottom: 0;
      left: 0;
      &__all {
        left: 20px;
        font-weight: lighter;
        color: #00A0E9;
        padding: 0 0px 6px 0px;
        border-bottom: 1px solid #00A0E9;
        cursor:pointer;
      }
      &__file {

      }
    }
  }
  &__right {
    position: relative;
    &__btn {
      &__trash {
        position: relative;
        top: -2px;
        width: 108px;
        height: 47px;
        margin-right: 20px;
        font-size: 16px;
        font-weight: bold;
        color: #D3D3DD!important;
        border: 1px solid #D3D3DD;
        .iconfont {
          font-size: 18px;
          margin-right: 5px;
        }
        .btn__default()
      }
      &__new {
        background-color: #00A0E9;
        color: #fff;
        width: 140px;
        height: 47px;
        font-size: 18px;
        .btn__default()
      }
    }
    &__info {

    }
    .wrap {
      position: absolute;
      right: 0;
      bottom: -5px;
      width: 400px;
      height: 50px;
      line-height: 46px;
      .search {
        height: 46px;
        line-height: 46px;
        border-radius: 23px;
        border: 1px solid #E1E1E8;
        padding: 10px;
        ::v-deep(input) {
          margin-left: 5px;
          font-size: 16px;
        }
        ::v-deep(.ant-input-suffix) {
          svg {
            width: 27px;
            height: 27px;
          }
        }
      }
    }
  }
}
.list {
  
}
.pager {
  text-align: end;
  margin-right: 50px;
  margin-top: 16px;
  padding-bottom: 50px;
}
</style>