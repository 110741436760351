<!--
 * @Description: 问卷
 * @version: 
 * @Author: PSG
 * @Date: 2021-03-15 17:51:14
 * @LastEditors: PSG
 * @LastEditTime: 2021-07-28 16:36:23
-->
<template>
  <div class="item" >
    <div class="top">
      <h1 class="top-title" :title="item.title">{{item.title}}</h1>
      <div class="top-info">
        <div class="top-info-id">ID: {{item.dateNumId}}</div>
        <div class="top-info-status">
          <template v-if="item.ifRelease"><span class="top-info-status-release"></span><span class="text">已发布</span></template>
          <template v-else><span class="top-info-status-norelease"></span>未发布</template>
        </div>
        <div @click="handleRecycleClick(item.id)" class="top-info-total">答卷：{{item.validQuestionnaireNum || 0}} / {{totalQuestionnaireNum}}</div>
        <div>{{formatDate(item.modifyTime)}}</div>
      </div>
    </div>
    <div class="bottom">
      <div class="bottom-btn">
        <div @click="handleEditClick(item)"><svg-icon iconName="edit"/>编辑</div>
        <div @click="handlePreviewClick(item.id)"><svg-icon iconName="eye" />预览</div>
        <div @click="handleCopyClick(item)"><svg-icon iconName="copy"/>复制</div>
        <div @click="handleDeleteClick(item.id)"><svg-icon iconName="delete"/>删除</div>
        <div @click="handleReleaseClick(item.id, item.ifRelease)" v-if="item.ifRelease"><svg-icon iconName="stop"/>暂停</div>
        <div @click="handleReleaseClick(item.id, item.ifRelease)" v-else><svg-icon iconName="release"/>发布</div>
      </div>
      <div class="bottom-func">
        <div @click="handleSendClick(item.id)" class="bottom-func-send"><svg-icon iconName="send"/>问卷派发</div>
        <div @click="handleRecycleClick(item.id)" class="bottom-func-recycle"><svg-icon iconName="situation"/>回收情况</div>
        <div @click="handleAnalysisClick(item.id)" class="bottom-func-analysis"><svg-icon iconName="analysis"/>结果分析</div>
        <!-- <div class="item__func__file">默认文件夹</div> -->
      </div>
    </div>
  </div>
</template>

<script>
import { computed, ref } from "vue"
import { formatDate } from '@/utils/date.js'
import { useRouter } from 'vue-router'

const getTotalNumEffect = (props) => {
  const totalQuestionnaireNum = computed(() => {
    return props.item.validQuestionnaireNum + props.item.inValidQuestionnaireNum
  })
  return { totalQuestionnaireNum }
}

const handleClickEffect = (emit) => {
  const handlePreviewClick = (id) => { emit('preview', id) }
  const handleEditClick = (item) => { emit('eidt', item) }
  const handleDeleteClick = (id) => { emit('delete', id) }
  const handleCopyClick = (item) => { emit('copy', item) }
  const handleReleaseClick = (id, ifRelease) => { emit('release', id, ifRelease) }
  const handleSendClick = (id) => { emit('send', id) }
  const handleRecycleClick = (id) => { emit('recycle', id) }
  const handleAnalysisClick = (id) => { emit('analysis', id)}
  return { 
    handlePreviewClick, 
    handleEditClick, 
    handleDeleteClick,
    handleCopyClick, 
    handleReleaseClick,
    handleRecycleClick,
    handleSendClick,
    handleAnalysisClick,
  }
}

export default ({
  name: 'QuestionnaireItem',
  props: ['item'],
  setup(props, { emit }) {
    const { totalQuestionnaireNum } = getTotalNumEffect(props) 
    const { handlePreviewClick, handleEditClick, handleDeleteClick, handleCopyClick, 
      handleReleaseClick, handleRecycleClick, handleSendClick, handleAnalysisClick } = handleClickEffect(emit)
    return {
      formatDate,
      totalQuestionnaireNum,
      
      handlePreviewClick,
      handleEditClick,
      handleDeleteClick,
      handleCopyClick,
      handleReleaseClick,
      handleRecycleClick,
      handleSendClick,
      handleAnalysisClick,
    }
  },
})
</script>

<style scoped lang="less">
.item { 
  margin: 0 50px 16px 50px;
  height: 172px;
  display: flex;
  flex-direction: column;
  background-color: #fbfcff;
  cursor:pointer;
  &:hover {
    background-color: @hoverBackground;
  }
  .top {
    display: flex;
    justify-content: space-between;
    height: 77px;
    line-height: 77px;
    padding: 0 24px;
    font-weight: 100;
    border-bottom: 1px solid #E1E1E8;
    .top-title {
      display: block;
      color: #2D2D33;
      font-size: 20px;
      width: 523px;
      margin: 0;
      overflow:hidden;//超出的隐藏
      text-overflow:ellipsis;//省略号
      white-space:nowrap;//强制一行显示
    }
    .top-info {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 670px;
      font-size: 20px;
      font-weight: 100;
      color: #7F7F88;
      .top-info-id {
        height: 30px;
        line-height: 30px;
        border: 1px solid #ebebeb;
        border-radius: 4px;
        padding: 0 8px;
        color: #7F7F88;
        background-color: #fff;
      }
      .top-info-status {
        display: flex;
        align-items: center;
        .top-info-status-release {
          display: inline-block;
          width: 16px;
          height: 16px;
          border-radius: 16px;
          background-color: #FF7700;
          line-height: 16px;
          margin-right: 10px;
          color: #7F7F88;
        }
        .text {
          color: #FF7700;
        }
        .top-info-status-norelease {
          display: inline-block;
          width: 16px;
          height: 16px;
          border-radius: 16px;
          background-color: #7f7f7f;
          line-height: 16px;
          margin-right: 10px;
          color: #7F7F88;
        }
      }
    }
  }
  .bottom {
    display: flex;
    justify-content: space-between;
    margin-top: 32px;
    font-size: 20px;
    font-weight: 100;
    color: #7F7F88;
    .bottom-btn {
      display: flex;
      justify-content: space-between;
      width: 420px;
      margin-left: 24px;
      .svg-icon {
        display: inline-block;
        margin-right: 4px;
        height: 20px;
        width: 20px;
        color: red;
      }
      div {
        .pointer()
      }
    }
    .bottom-func {
      display: flex;
      justify-content: space-between;
      width: 380px;
      font-size: 20px;
      font-weight: 100;
      padding-right: 24px;
      margin-left: 24px;
      color: #7F7F88;
      cursor: pointer;    
      .svg-icon {
        display: inline-block;
        margin-right: 4px;
        height: 20px;
        width: 20px;
        color: red;
      }
      .bottom-func-send {
          
      }
      .bottom-func-recycle {
              
      }
      .bottom-func-analysis {

      }
      .bottom-func-file {

      }
    }
  }
}
</style>
