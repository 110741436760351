/*
 * @Description: 预览操作
 * @version: 
 * @Author: HWL
 * @Date: 2021-07-07 14:05:15
 * @LastEditors: HWL
 * @LastEditTime: 2021-07-07 14:38:36
 */
import { releaseQuestionnaire, cancelReleaseQuestionnaire } from '@/api/questionnaire'
import { message } from 'ant-design-vue'
import { useRouter } from 'vue-router'

function releaseEffect() {
  const router = useRouter()
  const buildTitle = (ifRelease) => {
    const tips = ifRelease ? '暂停' : ''
    return `确定要${tips}发布问卷吗？`
  }

  const buildContext = (ifRelease) => {
    const context = ifRelease ? '(暂停之后，派发问卷链接将失效)' : '(是否发布问卷)'
    return context
  }

  const handleOk = async (params) => {
    const { id, ifRelease, refresh } = params
    console.log(params)
    const tips = ifRelease ? '暂停' : ''
    let res
    if (ifRelease) {
      res = await cancelReleaseQuestionnaire(id)
    } else {
      res = await releaseQuestionnaire(id)
    }
    if (res.status === 200 && res.data?.code === '00000') {
      message.success(`${tips}发布成功`)
      !tips && router.push(`/detail/${id}/dispatchQuestion`)
      tips && refresh()
    } else {
      message.error(`${tips}发布失败，失败原因：${res.msg}`)
    }
  }

  return {
    buildTitle,
    buildContext,
    handleOk
  }
}

export default releaseEffect
