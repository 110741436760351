/*
 * @Description: 删除操作
 * @version: 
 * @Author: HWL
 * @Date: 2021-07-07 10:54:10
 * @LastEditors: HWL
 * @LastEditTime: 2021-07-07 11:47:43
 */
import { deleteQuestionnaire } from '@/api/questionnaire'
import { message } from 'ant-design-vue'

function delEffect() {
  const handleOk = async (params) => {
    const res = await deleteQuestionnaire({
      questionnaireProtoId: params.id,
      option: "SOFT_DELETE"
    })
    if (res.status === 200 && res.data?.code === '00000') {
      message.success('删除成功')
    } else {
      message.error('删除失败，失败原因：' + res.msg)
    }
  }

  return {
    handleOk
  }
}

export default delEffect
